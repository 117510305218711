/* src/Grid.css */
.grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

}

.grid {
  display: inline-block;
}

.row {
  display: flex;
  width: auto;
  justify-content: center;
}

.cell {
  width: 20px;
  height: 20px;
  border: .1px solid black;
  position: relative; /* Add position relative for proper positioning of droplet and splashes */
}

.droplet {
  width: 10px;
  height: 15px;
  position: relative;
  border-radius: 50%;
  clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  opacity: 1;
}

.splash {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: relative;
  left: 3px;
  bottom: 5px;
  opacity: 1;
}

@keyframes drop {
  0% {
    transform: translateY(-300%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

@keyframes splash1 {
  0% {
    transform: translateX(0px);

    opacity: 0;
  }
  50% {
    opacity: 1;
  } 
  100% {
    transform: translateX(-40px);

    opacity: 0;
  }
}
@keyframes splash2 {
  0% {
    transform: translateX(0px);

    opacity: 0;
  }
  50% {
    opacity: 1;
  } 
  100% {
    transform: translateX(40px);

    opacity: 0;
  }
}
@keyframes splash3 {
  0% {
    transform: translateX(0px);

    opacity: 0;
  }
  50% {
    opacity: 1;
  } 
  100% {
    transform: translateY(-40px);

    opacity: 0;
  }
}

.cell.filled .droplet {
  animation: drop 0.5s ease forwards; /* Apply the drop animation to filled cells */
}

.cell.filled .splash1 {
  animation: splash1 0.5s ease 0.2s forwards; /* Apply the splash animation with delay */
}

.cell.filled .splash2 {
  animation: splash2 0.5s ease 0.2s forwards; /* Apply the splash animation with delay */
}

.cell.filled .splash3 {
  animation: splash3 0.5s ease 0.2s forwards; /* Apply the splash animation with delay */
}
