.color-square {
    width: 50px;
    height: 50px;
    border: none;
    transition: .25s;
}

.color-square:hover {
    transform: scale(120%);
}

.color-pickers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.color-picker {
    margin: 10px;
}

.add-color-btn {
    min-width: 50px;
    height: 50px;
    position: relative;
    margin: 10px;
    transition: .5s;

}

.add-color-btn:hover {
    box-shadow: inset 0 0 0 2em yellow;
    transform: scale(120%);
}