.Form-page {
    background-image: url('../../assets/images/Website_background.png');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    text-align: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-around;
    padding: 20px;

}

.form-container {
    border-radius: 25px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    background-color: #3c3e52;
    padding: 15px;
}

.button-container {
    margin-bottom: 15px;
}

.dialogue-container {
    margin: 30px;
}

.css-12rl710-MuiPaper-root-MuiDialog-paper {
    background-color: #272833 !important;
}