body, html {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  background-color: #fff;
  min-height: 100vh;
  color: white;
}

h1 {
  font-size: 72px;
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 36px;
  }
}